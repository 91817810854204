window.addEventListener('DOMContentLoaded', function() {


    var filter_form = document.getElementById('filter');
    
    if (filter_form) {

        var select_buttons_arr = document.getElementsByClassName('form__select-button');
        var select_buttons_title_arr = document.getElementsByClassName('form__select-button-title');
        var select_lists_arr = document.getElementsByClassName('select-list');
        var apply_buttons_arr = document.getElementsByClassName('apply-button');
        var reset_buttons_arr = document.getElementsByClassName('reset-button');
        var filter_section = document.getElementById('filter-section');
        var filter_container = document.getElementById('filter-container');

        for (var i = 0; i < select_buttons_arr.length; i++) {
            
            select_buttons_arr[i].setAttribute('data-id', i);

            apply_buttons_arr[i].setAttribute('data-id', i);

            /**
             * Open/close select-list in form by press select proper button
             */
            select_buttons_arr[i].addEventListener('click', function() {
                var list_id = this.getAttribute('data-id');
                removeCssClass(select_lists_arr, 'select-list--open', list_id);
                removeCssClass(select_buttons_arr, 'form__select-button--active', list_id);
                this.classList.toggle('form__select-button--active');
                select_lists_arr[list_id].classList.toggle('select-list--open');
            });
            /**
             * -------------------------------------------------------
             */


            /**
             * Close select-list in form by press apply button in proper select-list
             */
            apply_buttons_arr[i].addEventListener('click', function() {
                removeCssClass(select_lists_arr, 'select-list--open');
                removeCssClass(select_buttons_arr, 'form__select-button--active');
            });
            /**
             * -------------------------------------------------------
             */


            /**
             * Unchecked checkboxes in select-list at reset button press
             */
            reset_buttons_arr[i].addEventListener('click', function(event) {

                var select_list_type = this.getAttribute('data-type');
                var fieldset_elements = false;

                if (select_list_type == 'estate') {
                    select_buttons_title_arr[0].textContent = select_buttons_title_arr[0].getAttribute('data-default-title');
                    for (var j = 0; j < properties_types.length; j++) {
                        uncheckedCheckboxes(properties_types[j].elements);
                        addCssClass(properties_types, 'select-list__type--disabled', null);
                    }
                } else if (select_list_type == 'districts') {
                    select_buttons_title_arr[1].textContent = select_buttons_title_arr[1].getAttribute('data-default-title');
                    fieldset_elements = filter_form.elements.districts.elements;
                    uncheckedCheckboxes(fieldset_elements, null);
                } else if (select_list_type == 'rooms') {
                    select_buttons_title_arr[2].textContent = select_buttons_title_arr[2].getAttribute('data-default-title');
                    fieldset_elements = filter_form.elements.rooms.elements;
                    uncheckedCheckboxes(fieldset_elements, null);
                } else if (select_list_type == 'floors') {
                    select_buttons_title_arr[3].textContent = select_buttons_title_arr[3].getAttribute('data-default-title');
                    fieldset_elements = filter_form.elements.floors.elements;
                    uncheckedCheckboxes(fieldset_elements, null);
                }
            });
            /**
             * -------------------------------------------------------
             */
        }

        /**
         * Close all select-lists in form on press "Esc"
         */
        document.addEventListener('keyup', function(event) {
            if (event.keyCode == 27) {
                removeCssClass(select_lists_arr, 'select-list--open');
                removeCssClass(select_buttons_arr, 'form__select-button--active');
            }
        });
        /**
         * -------------------------------------------------------
         */

        document.addEventListener('click', function(event) {
            if (event.target == filter_container || event.target == filter_section) {
                removeCssClass(select_lists_arr, 'select-list--open');
                removeCssClass(select_buttons_arr, 'form__select-button--active');
            }
        });


        /**
         * Changes css classes on select type and subtypes of estate (first select list and button)
         */
        var properties_types = document.getElementsByClassName('select-list__type');
        var rooms_container = document.getElementById('rooms');
        var floors_container = document.getElementById('floors');
        var price_sm_container = document.getElementById('price-sm');
        var properties_notation = document.getElementById('properties_notation');

        for (var i = 0; i < properties_types.length; i++) {

            properties_types[i].setAttribute('data-id', i);

            properties_types[i].addEventListener('click', function(event) {
                var list_id = this.getAttribute('data-id');
                if (event.target.tagName == 'INPUT') {
                    addCssClass(properties_types, 'select-list__type--disabled', list_id);
                    this.classList.remove('select-list__type--disabled');
                    for (var j = 0; j < properties_types.length; j++) {
                        if (j == list_id) {
                            continue;
                        }
                        uncheckedCheckboxes(properties_types[j].elements);
                    }

                    /*
                     * Rename select button of type estate
                     */
                    select_buttons_title_arr[0].textContent = this.getAttribute('data-title');
                    /*
                     * -----------------------------------------------
                     */

                    if (!isCheckedElements(this.elements)) {
                        this.classList.add('select-list__type--disabled');
                        select_buttons_title_arr[0].textContent = select_buttons_title_arr[0].getAttribute('data-default-title');
                    }

                    if (list_id == 1 && event.target.value == 8) {
                        price_sm_container.classList.add('form__fieldset--disabled');
                        rooms_container.classList.remove('form__fieldset--disabled');
                        floors_container.classList.add('form__fieldset--disabled');
                        properties_notation.classList.add('select-list__notation--disabled');
                        filter_form.elements.area.elements[0].setAttribute('data-title-after', 'м.кв.');
                        filter_form.elements.area.elements[1].setAttribute('data-title-after', 'м.кв.');
                        clearInputValue(filter_form.elements.price_sm.elements);
                        clearInputValue(filter_form.elements.price_total.elements);
                        clearInputValue(filter_form.elements.area.elements);
                        filter_form.elements.realty_type.value = 2;
                        uncheckedCheckboxes(filter_form.elements.floors.elements, null);
                        
                    } else if(list_id == 1 && event.target.value == 9) {
                        floors_container.classList.remove('form__fieldset--disabled');
                        price_sm_container.classList.add('form__fieldset--disabled');
                        rooms_container.classList.add('form__fieldset--disabled');
                        properties_notation.classList.add('select-list__notation--disabled');
                        filter_form.elements.area.elements[0].setAttribute('data-title-after', 'м.кв.');
                        filter_form.elements.area.elements[1].setAttribute('data-title-after', 'м.кв.');
                        clearInputValue(filter_form.elements.price_sm.elements);
                        clearInputValue(filter_form.elements.price_total.elements);
                        clearInputValue(filter_form.elements.area.elements);
                        filter_form.elements.realty_type.value = 2;
                        uncheckedCheckboxes(filter_form.elements.rooms.elements, null);
                    } else if (list_id == 2) {
                        price_sm_container.classList.remove('form__fieldset--disabled');
                        rooms_container.classList.add('form__fieldset--disabled');
                        floors_container.classList.add('form__fieldset--disabled');
                        filter_form.elements.price_sm.elements[0].placeholder = 'Цена за сотку от';
                        filter_form.elements.price_sm.elements[1].placeholder = 'Цена за сотку до';
                        filter_form.elements.price_sm.elements[0].setAttribute('data-title-after', 'за сотку');
                        filter_form.elements.price_sm.elements[1].setAttribute('data-title-after', 'за сотку');
                        filter_form.elements.area.elements[0].setAttribute('data-title-after', 'соток');
                        filter_form.elements.area.elements[1].setAttribute('data-title-after', 'соток');
                        properties_notation.classList.remove('select-list__notation--disabled');
                        clearInputValue(filter_form.elements.price_sm.elements);
                        clearInputValue(filter_form.elements.price_total.elements);
                        clearInputValue(filter_form.elements.area.elements);
                        filter_form.elements.realty_type.value = 3;

                        uncheckedCheckboxes(filter_form.elements.floors.elements, null);
                        uncheckedCheckboxes(filter_form.elements.rooms.elements, null);
                    } else if (list_id == 0) {
                        filter_form.elements.price_sm.elements[0].placeholder = 'Цена за м.кв. от';
                        filter_form.elements.price_sm.elements[1].placeholder = 'Цена за м.кв. до';
                        filter_form.elements.price_sm.elements[0].setAttribute('data-title-after', 'за м.кв.');
                        filter_form.elements.price_sm.elements[1].setAttribute('data-title-after', 'за м.кв.');
                        filter_form.elements.area.elements[0].setAttribute('data-title-after', 'м.кв.');
                        filter_form.elements.area.elements[1].setAttribute('data-title-after', 'м.кв.');
                        price_sm_container.classList.remove('form__fieldset--disabled');
                        rooms_container.classList.add('form__fieldset--disabled');
                        floors_container.classList.add('form__fieldset--disabled');
                        properties_notation.classList.remove('select-list__notation--disabled');
                        clearInputValue(filter_form.elements.price_sm.elements);
                        clearInputValue(filter_form.elements.price_total.elements);
                        clearInputValue(filter_form.elements.area.elements);
                        filter_form.elements.realty_type.value = 1;

                        uncheckedCheckboxes(filter_form.elements.floors.elements, null);
                        uncheckedCheckboxes(filter_form.elements.rooms.elements, null);
                        
                    }
                }
            });
        }
        /**
         * -------------------------------------------------------
         */


        /**
         * Checked toggle from districts and one area
         */
        var districts_fieldset = filter_form.elements.districts;
        var districts_fieldset_elements = districts_fieldset.elements;
        var districts_fieldset_length = districts_fieldset.elements.length;
        districts_fieldset.addEventListener('click', function(event) {

            var last_fieldset_element = districts_fieldset_elements[districts_fieldset_length - 1];

            if (event.target != last_fieldset_element) {
                last_fieldset_element.checked = false;
            }

            if (last_fieldset_element.checked == true) {
                uncheckedCheckboxes(districts_fieldset_elements, districts_fieldset_length - 1);
            }
            /*
             * Rename select button of districts
             */
            select_buttons_title_arr[1].textContent = getButtonName(districts_fieldset_elements, 'data-title', 'Несколько районов');
            /*
             * -------------------------------------------
             */
        });

        /**
         * -------------------------------------------------------
         */

        var rooms_fieldset = filter_form.elements.rooms;
        rooms_fieldset.addEventListener('click', function(event) {
            /*
             * Rename select button of rooms
             */
            select_buttons_title_arr[2].textContent = getButtonName(rooms_fieldset.elements, 'data-title', 'Несколько вариантов');
            /*
             * -------------------------------------------
             */
        });

        var floors_fieldset = filter_form.elements.floors;
        floors_fieldset.addEventListener('click', function(event) {
            /*
             * Rename select button of rooms
             */
            select_buttons_title_arr[3].textContent = getButtonName(floors_fieldset.elements, 'data-title', 'Несколько вариантов');
            /*
             * -------------------------------------------
             */
        });

        /**
         * Removes the specified class of the element.
         *
         * @param {object} arr
         * @param {string} css_class_name
         * @param {number} skipp
         * @returns {boolean}
         */
        function removeCssClass(arr, css_class_name, skipp) {
            if (typeof(arr) == 'object') {
                var arr_length = arr.length;
                for (var i = 0; i < arr_length; i++) {
                    if (i == skipp) {
                        continue;
                    }
                    arr[i].classList.remove(css_class_name);
                }
            } else {
                return false;
            }
        }
        /**
         * -------------------------------------------------------
         */


        /**
         * Removes the specified class of the element.
         *
         * @param {object} arr
         * @param {string} css_class_name
         * @param {number} skipp
         * @returns {boolean}
         */
        function addCssClass(arr, css_class_name, skipp) {
            if (typeof(arr) == 'object') {
                var arr_length = arr.length;
                for (var i = 0; i < arr_length; i++) {
                    if (i == skipp) {
                        continue;
                    }
                    arr[i].classList.add(css_class_name);
                }
            } else {
                return false;
            }
        }
        /**
         * -------------------------------------------------------
         */

        /**
         *
         * @param {object} elements
         * @param {number} skipp
         */
        function uncheckedCheckboxes (elements, skipp) {
            var elements_length = elements.length;
            for (var i = 0; i < elements_length; i++) {
                if (i == skipp) {
                    continue;
                }
                if (elements[i].checked == true) {
                    elements[i].checked = false;
                }
            }
        }
        /**
         * -------------------------------------------------------
         */

        function isCheckedElements(elements) {
            var elements_length = elements.length;
            for (var i = 0; i < elements_length; i++) {
                if (elements[i].checked == true) {
                    return true;
                }
            }
            return false;
        }

        function getButtonName(elements, data_attribute_name, many_values_title) {
            var elements_length = elements.length;
            var count_checked = 0;
            var index_checked;
            for (var i = 0; i < elements_length; i++) {
                if (elements[i].checked == true) {
                    count_checked++;
                    index_checked = i;
                }
            }
            if (count_checked == 1) {
                return elements[index_checked].getAttribute(data_attribute_name);
            }
            return many_values_title;
        }

        function clearInputValue(arr)
        {
            for (var i = 0; i < arr.length; i++) {
                arr[i].value = '';
            }
        }
    }

});
window.addEventListener('DOMContentLoaded', function() {

    var filter_form = document.getElementById('filter');

    if (filter_form) {

        var form_elements = filter_form.elements;

        for (var i = 0, array_length = form_elements.length; i < array_length; i++) {

            if (form_elements[i].getAttribute('data-value-type') == 'number') {

                /**
                 *
                 * RU:запрет на ввод в поля формы с типом значения 'число' всех символов кроме чисел
                 * EN:a ban on entry into the fields of the form with the value type 'number' of all characters except numbers
                 *
                 */
                form_elements[i].addEventListener('keypress', function(event) {
                    if (event.ctrlKey || event.altKey || event.metaKey) {
                        return;
                    }
                    var char = getChar(event);
                    if(char == null) {
                        return;
                    }
                    if (char < '0' || char > '9') {
                        event.preventDefault();
                        return false;
                    }

                    /**
                     * RU:если необходимо добавить точку (дробное число):
                     * EN:if you want to add a point (a fractional number):
                     *
                     */
                    /*
                    if (char == '.' && (this.value.split('.').length - 1 > 0)) {
                        event.preventDefault();
                        return false;
                    }
                    if (char != '.' && char < '0' || char > '9') {
                        event.preventDefault();
                        return false;
                    }
                    */
                });

                /**
                 *
                 * RU:очистка значения поля ввода от любых символов кроме цифр
                 * EN:cleaning input field values of any character except digits
                 *
                 */
                form_elements[i].addEventListener('focus', function(event) {
                    if (this.value != '') {
                        this.value = clearLetters(this.value);
                    }
                });

                /**
                 * RU:изменение занчения поля ввода (добавление до и после значние пояснительного текста), после потери фокуса
                 * EN:
                 */
                form_elements[i].addEventListener('blur', function(event) {
                    if (this.value != '') {
                        if (this.getAttribute('data-currency')) {
                            this.value = changeInputValue(this.value, this.getAttribute('data-title-before'), this.getAttribute('data-currency') + ' ' + this.getAttribute('data-title-after'));
                        } else {
                            this.value = changeInputValue(this.value, this.getAttribute('data-title-before'), this.getAttribute('data-title-after'));
                        }
                    }
                });
            }
        }

        var price_unit_at = filter_form.elements.price_unit_at;
        var price_unit_to = filter_form.elements.price_unit_to;
        var price_total_at = filter_form.elements.price_total_at;
        var price_total_to = filter_form.elements.price_total_to;
        var area_at = filter_form.elements.area_at;
        var area_to = filter_form.elements.area_to;

        area_at.addEventListener('input', function(event) {

            if (price_unit_at.disabled == true) {
                var y = clearLetters(price_total_at.value);
                var x = this.value / y;
                price_unit_at.value = changeInputValue(x, price_unit_at.getAttribute('data-title-before'), price_unit_at.getAttribute('data-currency') + ' ' + price_unit_at.getAttribute('data-title-after'));
            } else if (isNumeric(this.value) && price_unit_at.value != '') {
                var y = clearLetters(price_unit_at.value);
                var x = this.value * y;
                price_total_at.value = changeInputValue(x, price_total_at.getAttribute('data-title-before'), price_total_at.getAttribute('data-currency') + ' ' + price_total_at.getAttribute('data-title-after'));
            }

            if (this.value == '') {
                price_total_at.value = '';
            }

        });

        price_total_at.addEventListener('input', function(event) {
            if (isNumeric(this.value) && area_at.value != '') {
                var y = clearLetters(area_at.value);
                var x = this.value / y;
                price_unit_at.value = changeInputValue(x, price_unit_at.getAttribute('data-title-before'), price_unit_at.getAttribute('data-currency') + ' ' + price_unit_at.getAttribute('data-title-after'));
            }

            if (this.value == '') {
                price_unit_at.value = '';
                price_unit_at.disabled = false;
            }

            if (this.value != '') {
                price_unit_at.disabled = true;
            }

        });

        price_unit_at.addEventListener('input', function(event) {
            if (isNumeric(this.value) && area_at.value != '') {
                var y = clearLetters(area_at.value);
                var x = this.value * y;
                price_total_at.value = changeInputValue(x, price_total_at.getAttribute('data-title-before'), price_total_at.getAttribute('data-currency') + ' ' + price_total_at.getAttribute('data-title-after'));
            }

            if (this.value == '') {
                price_total_at.value = '';
                price_total_at.disabled = false;
            }

            if (this.value != '') {
                price_total_at.disabled = true;
            }
        });


        area_to.addEventListener('input', function(event) {
            if (price_unit_to.disabled == true) {
                var y = clearLetters(price_total_to.value);
                var x = this.value / y;
                price_unit_to.value = changeInputValue(x, price_unit_to.getAttribute('data-title-before'), price_unit_to.getAttribute('data-currency') + ' ' + price_unit_to.getAttribute('data-title-after'));
            } else if (isNumeric(this.value) && price_unit_to.value != '') {
                var y = clearLetters(price_unit_to.value);
                var x = this.value * y;
                price_total_to.value = changeInputValue(x, price_total_to.getAttribute('data-title-before'), price_total_to.getAttribute('data-currency') + ' ' + price_total_to.getAttribute('data-title-after'));
            }

            if (this.value == '') {
                price_total_to.value = '';
            }
        });

        price_total_to.addEventListener('input', function(event) {
            if (isNumeric(this.value)  && area_to.value != '') {
                var y = clearLetters(area_to.value);
                var x = this.value / y;
                price_unit_to.value = changeInputValue(x, price_unit_to.getAttribute('data-title-before'), price_unit_to.getAttribute('data-currency') + ' ' + price_unit_to.getAttribute('data-title-after'));
            }

            if (this.value == '') {
                price_unit_to.value = '';
                price_unit_to.disabled = false;
            }

            if (this.value != '') {
                price_unit_to.disabled = true;
            }

        });

        price_unit_to.addEventListener('input', function(event) {
            if (isNumeric(this.value) && area_to.value != '') {
                var y = clearLetters(area_to.value);
                var x = this.value * y;
                price_total_to.value = changeInputValue(x, price_total_to.getAttribute('data-title-before'), price_total_to.getAttribute('data-currency') + ' ' + price_total_to.getAttribute('data-title-after'));
            }

            if (this.value == '') {
                price_total_to.value = '';
                price_total_to.disabled = false;
            }

            if (this.value != '') {
                price_total_to.disabled = true;
            }
        });


    }

    /**
     * FUNCTIONS
     */

    function multiplicationInputValues(x, y) {
        clearLetters(x.value);
        clearLetters(y.value);
        return x.value * y.value;
    }

    /**
     *
     * @param {number} value
     * @param {string} before
     * @param {string} after
     * @returns {string}
     */
    function changeInputValue(value, before, after) {
        clearLetters(value);
        return before + ' ' + value + ' ' + after;
    }

    /**
     *
     * @param n
     * @returns {boolean}
     */
    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    /**
     *
     * @param event
     * @returns {*}
     */
    function getChar(event) {
        if (event.which == null) {
            if (event.keyCode < 32) return null;
            return String.fromCharCode(event.keyCode);
        }
        if (event.which != 0 && event.charCode != 0) {
            if (event.which < 32) return null;
            return String.fromCharCode(event.which);
        }
        return null;
    }

    /**
     *
     * @param {string} str
     * @returns {string}
     */
    function clearLetters(str) {
        if (isNumeric(str)) {
            return str;
        }
        var words_arr = str.split(' ');
        return words_arr[1];
        // return str.replace(/[^0-9]/gim,'');
    }

});
window.addEventListener('DOMContentLoaded', function() {

    var filter_form = document.getElementById('filter');

    if (filter_form) {
        var currency_switcher = filter_form.elements.currency;
        var price_unit_at = filter_form.elements.price_unit_at;
        var price_unit_to = filter_form.elements.price_unit_to;
        var price_total_at = filter_form.elements.price_total_at;
        var price_total_to = filter_form.elements.price_total_to;

        for(var i = 0; i < currency_switcher.length; i++) {
            currency_switcher[i].addEventListener('click', function(event) {
                price_unit_at.value = '';
                price_unit_to.value = '';
                price_total_at.value = '';
                price_total_to.value = '';
                
                if (currency_switcher.value == 'uah') {
                    price_unit_at.setAttribute('data-currency', 'грн');
                    price_unit_to.setAttribute('data-currency', 'грн');
                    price_total_at.setAttribute('data-currency', 'грн');
                    price_total_to.setAttribute('data-currency', 'грн');
                } else if (currency_switcher.value == 'usd') {
                    price_unit_at.setAttribute('data-currency', '$');
                    price_unit_to.setAttribute('data-currency', '$');
                    price_total_at.setAttribute('data-currency', '$');
                    price_total_to.setAttribute('data-currency', '$');
                }
            });
        }

    }
});
$(document).ready(function () {
    $('#carousel').slick({
        dots: false,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: "<button type=\"button\" class=\"slick-prev\"><i class=\"fa fa-angle-left\" aria-hidden=\"true\"></i></button>",
        nextArrow: "<button type=\"button\" class=\"slick-next\"><i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i></button>",
        responsive: [
            {
                breakpoint: 1130,
                settings: {
                    arrows: false,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 390,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    });
});
(function () {
    var link_navigation = document.getElementsByClassName('scroll-to');
    if (link_navigation) {
        var speed = 0.2;
        var link_navigation_length = link_navigation.length;
        for (var i = 0; i < link_navigation_length; i++) {
            link_navigation[i].addEventListener('click', function (event) {
                event.preventDefault();
                var scrolling = window.pageYOffset;
                var target = this.getAttribute('data-anchor');
                var indent = document.getElementById(target).getBoundingClientRect().top - 100;
                var start = null;
                requestAnimationFrame(step);
                function step(time) {
                    if (start === null) start = time;
                    var progress = time - start;
                    var position = (indent < 0 ? Math.max(scrolling - progress / speed, scrolling + indent) : Math.min(scrolling + progress / speed, scrolling + indent))
                    window.scrollTo(0, position);
                    if (position != scrolling + indent) {
                        requestAnimationFrame(step)
                    } else {
                        location.target = target;
                    }
                }
            }, false);
        }
    }
})();
(function () {
    var btn_scroll_up = document.getElementById('btn__scroll_up');
    var flag = true;
    document.addEventListener('scroll', function() {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 300 && flag) {
            btn_scroll_up.classList.add('button-up--visible');
            flag = !flag;

        } else if (scrollTop < 300 && !flag) {
            btn_scroll_up.classList.remove('button-up--visible');
            flag = !flag;
        }
    });
})();
(function () {


    var popular_carousel = document.getElementById('popular_carousel');
    var object_list = document.getElementById('object_list');
    var object = document.getElementById('object');


    if (popular_carousel) {
        popular_carousel.addEventListener('click', addToFavorites);
    }

    if (object_list) {
        object_list.addEventListener('click', addToFavorites);
        object_list.addEventListener('click', removeFavorites);
    }

    if (object) {
        object.addEventListener('click', addToFavorites);
        object.addEventListener('click', removeFavorites);
    }

    var fav_link = document.getElementById('fav_header_link');
    var fav_link_icon = document.getElementById('fav_header_icon');
    var popup_message = document.getElementById('popup_message_carousel');

    function removeFavorites(event) {
        var target = event.target;
        while (target != this) {
            if (target.hasAttribute('data-action') && target.getAttribute('data-action') == 'remove_favorites') {

                var items_list = document.getElementsByClassName('objects-list__item');

                var object_id = target.getAttribute('data-object-id');
                var brp_favorites = getCookie('brp_fav');
                brp_favorites = JSON.parse(brp_favorites);

                var count_items = brp_favorites.length - 1;
                if (brp_favorites.length > 1) {
                    var deleted_item = brp_favorites.indexOf(object_id);
                    brp_favorites.splice(deleted_item, 1);
                    brp_favorites = JSON.stringify(brp_favorites);
                    setCookie('brp_fav', brp_favorites, options = {
                        expires: 72000,
                        path: '/'
                    });
                    items_list[target.getAttribute('data-item-num')].classList.add('objects-list__item--hide');
                    fav_link.textContent = 'В избранном ' + count_items + ' объекта(ов)';

                } else {
                    deleteCookie('brp_fav');
                    window.location.reload();
                }
                break;
            }
            target = target.parentNode;
        }
    }

    function addToFavorites(event) {
        var target = event.target;

        while (target != this) {
            if (target.hasAttribute('data-action') && target.getAttribute('data-action') == 'add_favorites') {

                var object_id = target.getAttribute('data-object-id');
                var brp_favorites = getCookie('brp_fav');
                var count_items = 0;

                if (brp_favorites) {

                    brp_favorites = JSON.parse(brp_favorites);
                    if (!brp_favorites.some(function(item) { return item == object_id; })) {
                        brp_favorites.push(object_id);
                    }
                    
                    count_items = brp_favorites.length;
                    brp_favorites = JSON.stringify(brp_favorites);
                    setCookie('brp_fav', brp_favorites, options = {
                        expires: 72000,
                        path: '/'
                    });
                } else {
                    brp_favorites = [];
                    brp_favorites.push(object_id);
                    brp_favorites = JSON.stringify(brp_favorites);
                    setCookie('brp_fav', brp_favorites, options = {
                        expires: 72000,
                        path: '/'
                    });
                    count_items = 1;
                }
                target.setAttribute('data-action', 'none');

                var favorites_icon = target.getElementsByTagName('I')[0];
                favorites_icon.classList.remove('fa-star-o');
                favorites_icon.classList.add('fa-star');

                var favorites_tooltip = target.getElementsByTagName('span')[0];

                if (favorites_tooltip) {
                    favorites_tooltip.classList.remove('tooltip--coral');
                    favorites_tooltip.classList.add('tooltip--blue-dark');
                    favorites_tooltip.textContent = 'Объект в избранном';
                }

                if (target.getAttribute('data-view') == 'full') {
                    target.innerHTML = '';
                    var icon = document.createElement('i');
                    var link = document.createElement('a');

                    icon.classList.add('fa', 'fa-star');
                    link.classList.add('color--blue-dark');
                    link.href = '/favorites';
                    link.textContent = 'Избранное';
                    target.appendChild(icon);
                    target.appendChild(link);

                }
                if (fav_link_icon && fav_link_icon.classList.contains('fa-star-o')) {
                    fav_link_icon.classList.remove('fa-star-o');
                    fav_link_icon.classList.add('fa-star');
                }

                fav_link.textContent = 'В избранном ' + count_items + ' объекта(ов)';
                popup_message.classList.add('popup-message--show');
                setTimeout(function() {
                    popup_message.classList.remove('popup-message--show');
                }, 2500);
                break;
            }
            target = target.parentNode;
        }
    }

    function getCookie(name) {
        var matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : false;
    }

    function setCookie(name, value, options) {
        options = options || {};

        var expires = options.expires;

        if (typeof expires == "number" && expires) {
            var d = new Date();
            d.setTime(d.getTime() + expires * 1000);
            expires = options.expires = d;
        }
        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString();
        }

        value = encodeURIComponent(value);

        var updatedCookie = name + "=" + value;

        for (var propName in options) {
            updatedCookie += "; " + propName;
            var propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += "=" + propValue;
            }
        }

        document.cookie = updatedCookie;
    }

    function deleteCookie(name) {
        setCookie(name, "", {
            expires: -1
        })
    }
})();
(function () {
    var filter_form = document.forms.filter_form;

    if (filter_form) {
        var fieldset__operation_types = filter_form.elements.operation_types;
        var fieldset__land = filter_form.elements.land;
        fieldset__operation_types.addEventListener('change', function() {
            if (filter_form.elements.operation_id[0].checked) {
                fieldset__land.classList.add('display--none');
                uncheckedCheckboxes(fieldset__land.elements, null);
            } else {
                fieldset__land.classList.remove('display--none');
            }

        });
    }

    function uncheckedCheckboxes (elements, skipp) {
        var elements_length = elements.length;
        for (var i = 0; i < elements_length; i++) {
            if (i == skipp) {
                continue;
            }
            if (elements[i].checked == true) {
                elements[i].checked = false;
            }
        }
    }
})();
(function () {
    var filter_form = document.forms.filter_form;

    if (filter_form) {
        var select_lists_arr = document.getElementsByClassName('select-list');
        filter_form.addEventListener('click', function(event) {
            if (event.target.classList.contains('form__input-text')) {
                removeCssClass(select_lists_arr, 'select-list--open');
            }
        });
    }

    function removeCssClass(arr, css_class_name, skipp) {
        if (typeof(arr) == 'object') {
            var arr_length = arr.length;
            for (var i = 0; i < arr_length; i++) {
                if (i == skipp) {
                    continue;
                }
                arr[i].classList.remove(css_class_name);
            }
        } else {
            return false;
        }
    }

})();
(function () {

    var object = document.getElementById('object');

    if (object) {


/* ------------------------------------------------------------------------------------------ */
/* --------------------- Insert current image into main container BEGIN --------------------- */

        var gallery_items = document.getElementsByClassName('object-image_js');
        if (gallery_items) {
            var cnt__thumbnails_list = document.getElementById('cnt__thumbnails_list');
            var magnifier = document.getElementById('magnifier');
            var btn_more_image = document.getElementById('btn__more_image');
            var main_image_container = document.getElementById('object_cover');
            var current_image = main_image_container.getElementsByTagName('IMG');

            var thumb_containers_list = document.getElementsByClassName('thumbnails-list__item');

            var btn__prev = document.getElementById('btn__prev');
            var btn__next = document.getElementById('btn__next');

            cnt__thumbnails_list.addEventListener('click', function (event) {
                var target = event.target;

                if (target.parentNode.hasAttribute('data-image-type')) {

                    var image_src = target.getAttribute('src');
                    var image_data_id = target.parentNode.getAttribute('data-id');

                    if (target.parentNode.getAttribute('data-image-type') == 'photo') {
                        current_image[0].setAttribute('src', image_src);

                        btn__prev.setAttribute('data-current-thumb', image_data_id);
                        btn__next.setAttribute('data-current-thumb', image_data_id);

                    } else if (target.parentNode.getAttribute('data-image-type') == 'scheme') {
                        current_image[1].setAttribute('src', image_src);

                        btn__prev.setAttribute('data-current-thumb', image_data_id);
                        btn__next.setAttribute('data-current-thumb', image_data_id);
                    }

                    magnifier.href = '#image_' + image_data_id;
                    magnifier.setAttribute('data-id', image_data_id);

                    btn_more_image.href = '#image_' + image_data_id;
                    btn_more_image.setAttribute('data-id', image_data_id);

                    removeCssClass(thumb_containers_list, 'thumbnails-list__item--active');
                    target.parentNode.classList.add('thumbnails-list__item--active');
                }

            });
        }
/* --------------------- Insert current image into main container END --------------------- */
/* ---------------------------------------------------------------------------------------- */


/* ------------------------------------------------------------------------------ */
/* --------------------- Popup container open / close BEGIN --------------------- */

        var popup_gallery = document.getElementById('popup-gallery');
        var close_popup_button = document.getElementById('close-popup-button');
        var current_popup_image_id = 0;

        // if (!main_image_container) {
        //     var main_image_container = document.getElementById('object_cover');
        // }

        var object_cover_link = document.getElementById('object_cover_link');
        if (object_cover_link && magnifier) {
            // CHANGE THIS
            object_cover_link.addEventListener('click', function(event) {
                this.href = magnifier.href;
                popup_gallery.classList.add('popup-gallery--open');
                current_popup_image_id = magnifier.getAttribute('data-id');
                activePopUpThumb(current_popup_image_id, thumbnails_photo, 'active-thumbnail');
                console.log(current_popup_image_id);
            });
        }

        if (magnifier) {
            magnifier.addEventListener('click', function (event) {
                popup_gallery.classList.add('popup-gallery--open');
                current_popup_image_id = this.getAttribute('data-id');
                activePopUpThumb(current_popup_image_id, thumbnails_photo, 'active-thumbnail');
            });
        }

        if (btn_more_image) {
            btn_more_image.addEventListener('click', function (event) {
                popup_gallery.classList.add('popup-gallery--open');
                current_popup_image_id = this.getAttribute('data-id');
                activePopUpThumb(current_popup_image_id, thumbnails_photo, 'active-thumbnail');
            });
        }


        document.addEventListener('keydown',function(event) {
            if (event.keyCode == 27) {
                popup_gallery.classList.remove('popup-gallery--open');
            }
        });

        close_popup_button.addEventListener('click', closePopUp);


        function closePopUp() {
            popup_gallery.classList.remove('popup-gallery--open');
        }

/* --------------------- Popup container open / close END --------------------- */
/* ---------------------------------------------------------------------------- */


        var popup_gallery_items_container = document.getElementById('popup-gallery-items');
        var popup_gallery_items = document.getElementsByClassName('popup-gallery-items__item');

        var popup_gallery_items_length = popup_gallery_items.length;
        for (var i = 0; i < popup_gallery_items_length; i++) {
            popup_gallery_items[i].setAttribute('data-thumb-id', i);
            popup_gallery_items[i].setAttribute('data-offset', popup_gallery_items[i].getBoundingClientRect().top - 121); //121 - height og header
        }
        var scroll_top = 0;
        popup_gallery_items_container.addEventListener('scroll', function(event) {
            // event.stopPropagation();
            var scroll_top = popup_gallery_items_container.scrollTop;

            var elem_height = popup_gallery_items[current_popup_image_id].clientHeight + 15;
            var current_elem_scroll_top = popup_gallery_items[current_popup_image_id].getAttribute('data-offset');

            if (scroll_top > (+current_elem_scroll_top + +elem_height) && current_popup_image_id < popup_gallery_items_length) {
                current_popup_image_id++;
            } else if(scroll_top < current_elem_scroll_top - elem_height/2) {
                current_popup_image_id--;
            }
            if (parseInt(getComputedStyle(popup_gallery_thumnails_container).height) > 60) {
                scrollThumbContainer(current_popup_image_id);
            } else {
                setTimeout(function() {
                    var thumbnail_active_bottom = +thumbnails_photo[current_popup_image_id].getBoundingClientRect().bottom;
                    var thumbnail_active_top = +thumbnails_photo[current_popup_image_id].getBoundingClientRect().top;
                    var thumnails_container_bottom = +popup_gallery_thumnails_container.getBoundingClientRect().bottom - 33;//height of nav button
                    var thumnails_container_top = +popup_gallery_thumnails_container.getBoundingClientRect().top + 33;//height of nav button

                    if ((thumnails_container_bottom - thumbnail_active_top) < thumbnails_photo_height) {
                        var current_margin_top = parseInt(window.getComputedStyle(thubmnails_inner_container, null).marginTop);
                        var new_margin_top = thumnails_container_bottom - thumbnail_active_bottom + current_margin_top;
                        thubmnails_inner_container.style.marginTop = new_margin_top + 'px';
                    }
                    if ((thumbnail_active_top - thumnails_container_top) < 0) {
                        var current_margin_top = parseInt(window.getComputedStyle(thubmnails_inner_container, null).marginTop);
                        var new_margin_top = current_margin_top - (thumbnail_active_top - thumnails_container_top);
                        thubmnails_inner_container.style.marginTop = new_margin_top + 'px';
                    }
                }, 1000)
            }
            activePopUpThumb(current_popup_image_id, thumbnails_photo, 'active-thumbnail');
        });

        var gallery_item_height = popup_gallery_items[0].clientHeight;
        var popup_gallery_thumnails_container = document.getElementById('popup-gallery-thumnails');
        var thumbnails_photo = document.getElementsByClassName('popup-thumbnail-item');
        for (var i = 0; i < thumbnails_photo.length; i++){
            // thumbnails_photo[i].setAttribute('data-thumb-id', i);
            thumbnails_photo[i].addEventListener('click', function(event) {
                current_popup_image_id = this.getAttribute('data-thumb-id');
                scrollThumbContainer(current_popup_image_id);
                activePopUpThumb(current_popup_image_id, thumbnails_photo, 'active-thumbnail');
            });
        }
        var thumbnails_photo_height = thumbnails_photo[0].clientHeight;
        var thubmnails_inner_container = document.getElementById('thumnails-inner');


        var thumbnail_nav_prev = document.getElementById('thumbnail-nav-prev');
        var thumbnail_nav_next = document.getElementById('thumbnail-nav-next');
        thumbnail_nav_prev.addEventListener('click', function(event) {
            if (current_popup_image_id > 0) {
                popup_gallery_items_container.scrollTop = popup_gallery_items[current_popup_image_id - 1].getAttribute('data-offset');
                current_popup_image_id--;
                scrollThumbContainer(current_popup_image_id);
                activePopUpThumb(current_popup_image_id, thumbnails_photo, 'active-thumbnail');
            }
        });

        thumbnail_nav_next.addEventListener('click', function(event) {
            if (current_popup_image_id < popup_gallery_items_length - 1) {
                popup_gallery_items_container.scrollTop = popup_gallery_items[+current_popup_image_id + 1].getAttribute('data-offset');
                current_popup_image_id++;
                scrollThumbContainer(current_popup_image_id);
                activePopUpThumb(current_popup_image_id, thumbnails_photo, 'active-thumbnail');
            }
        });


        function activePopUpThumb(current_id, image_array, active_cls) {
            var image_array_length = image_array.length;
            for (var i = 0; i < image_array_length; i++){
                if (i == current_id) {
                    image_array[i].classList.add(active_cls);
                } else {
                    image_array[i].classList.remove(active_cls);
                }
            }
        }

        function scrollThumbContainer(current_id) {
            var thumbnail_active_bottom = +thumbnails_photo[current_id].getBoundingClientRect().bottom;
            var thumbnail_active_top = +thumbnails_photo[current_id].getBoundingClientRect().top;
            var thumnails_container_bottom = +popup_gallery_thumnails_container.getBoundingClientRect().bottom - 33;//height of nav button
            var thumnails_container_top = +popup_gallery_thumnails_container.getBoundingClientRect().top + 33;//height of nav button

            if ((thumnails_container_bottom - thumbnail_active_top) < thumbnails_photo_height) {
                var current_margin_top = parseInt(window.getComputedStyle(thubmnails_inner_container, null).marginTop);
                var new_margin_top = thumnails_container_bottom - thumbnail_active_bottom + current_margin_top;
                thubmnails_inner_container.style.marginTop = new_margin_top + 'px';
            }
            if ((thumbnail_active_top - thumnails_container_top) < 0) {
                var current_margin_top = parseInt(window.getComputedStyle(thubmnails_inner_container, null).marginTop);
                var new_margin_top = current_margin_top - (thumbnail_active_top - thumnails_container_top);
                thubmnails_inner_container.style.marginTop = new_margin_top + 'px';
            }
        }

        function removeCssClass($elements, $class_name)
        {
            $elements_length = $elements.length;
            for (var i = 0; i < $elements_length; i++) {
                $elements[i].classList.remove($class_name);
            }
        }

    }
    

    
   // function popUpCheck(popUp) {
   //     if (popUp.classList.contains('popup-gallery--open')) {
   //         document.body.addEventListener('wheel', function() {
   //             event.preventDefault();
   //         });
   //     }
   // }


})();
(function () {
    var image_switcher = document.getElementById('image_switcher');

    if (image_switcher) {
        var switcher_buttons = image_switcher.getElementsByClassName('image-switcher__item');
        var images_list = document.getElementsByClassName('object-image_js');
        var magnifier = document.getElementById('magnifier');
        var photo_main_thumb = document.getElementById('photo_main_thumb');
        var scheme_main_thumb = document.getElementById('scheme_main_thumb');

        var btn_more_image = document.getElementById('btn__more_image');

        var btn__prev = document.getElementById('btn__prev');
        var btn__next = document.getElementById('btn__next');

        image_switcher.addEventListener('click', function(event) {
            event.stopPropagation();
            var target = event.target;
            if (target.getAttribute('data-type') == 'photo') {
                removeCssClassByDataAttr(switcher_buttons, 'image-switcher__item--active', 'data-type', 'scheme');
                addCssClassByDataAttr(switcher_buttons, 'image-switcher__item--active', 'data-type', 'photo');

                removeCssClassByDataAttr(images_list, 'display--none', 'data-image-type', 'photo');
                addCssClassByDataAttr(images_list, 'display--none', 'data-image-type', 'scheme');

                magnifier.href = '#image_0';
                magnifier.setAttribute('data-id', 0);


                btn_more_image.href = '#image_0';
                btn_more_image.setAttribute('data-id', 0);

                images_list[0].src = images_list[0].getAttribute('data-base-image');

                removeCss(images_list, 'thumbnails-list__item--active');
                photo_main_thumb.classList.add('thumbnails-list__item--active');

                btn__prev.setAttribute('data-type', 'photo');
                btn__next.setAttribute('data-type', 'photo');
                btn__prev.setAttribute('data-current-thumb', 0);
                btn__next.setAttribute('data-current-thumb', 0);


            } else if (target.getAttribute('data-type') == 'scheme') {
                removeCssClassByDataAttr(switcher_buttons, 'image-switcher__item--active', 'data-type', 'photo');
                addCssClassByDataAttr(switcher_buttons, 'image-switcher__item--active', 'data-type', 'scheme');


                removeCssClassByDataAttr(images_list, 'display--none', 'data-image-type', 'scheme');
                addCssClassByDataAttr(images_list, 'display--none', 'data-image-type', 'photo');

                var scheme_main_thumb_id = scheme_main_thumb.getAttribute('data-id');

                magnifier.href = '#image_' + scheme_main_thumb_id;
                magnifier.setAttribute('data-id', scheme_main_thumb_id);

                btn_more_image.href = '#image_' + scheme_main_thumb_id;
                btn_more_image.setAttribute('data-id', scheme_main_thumb_id);

                images_list[1].src = images_list[1].getAttribute('data-base-image');

                removeCss(images_list, 'thumbnails-list__item--active');
                scheme_main_thumb.classList.add('thumbnails-list__item--active');

                btn__prev.setAttribute('data-type', 'scheme');
                btn__next.setAttribute('data-type', 'scheme');
                btn__prev.setAttribute('data-current-thumb', scheme_main_thumb_id);
                btn__next.setAttribute('data-current-thumb', scheme_main_thumb_id);

            }
        })
    }

    function removeCssClassByDataAttr($elements, $class_name, $data_attr, $data_value)
    {
        $elements_length = $elements.length;
        for (var i = 0; i < $elements_length; i++) {
            if ($elements[i].getAttribute($data_attr) == $data_value) {
                $elements[i].classList.remove($class_name);
            }
        }
    }

    function addCssClassByDataAttr($elements, $class_name, $data_attr, $data_value)
    {
        $elements_length = $elements.length;
        for (var i = 0; i < $elements_length; i++) {
            if ($elements[i].getAttribute($data_attr) == $data_value) {
                $elements[i].classList.add($class_name);
            }
        }
    }

    function removeCss($elements, $class_name)
    {
        $elements_length = $elements.length;
        for (var i = 0; i < $elements_length; i++) {
            $elements[i].classList.remove($class_name);
        }
    }
})();
(function () {
    var images_list = document.getElementsByClassName('object-image_js');

    if (images_list.length > 1) {
        var btn__prev = document.getElementById('btn__prev');
        var btn__next = document.getElementById('btn__next');

        var thumb_containers_list = document.getElementsByClassName('thumbnails-list__item');
        var thumbnails_list = document.getElementsByClassName('thumbnails-list__image');

        var magnifier = document.getElementById('magnifier');
        var btn_more_image = document.getElementById('btn__more_image');

        var current_image = 0;
        var current_type = btn__next.getAttribute('data-type');

        var cnt__thumbnails_inner = document.getElementById('cnt__thumbnails_list-inner');
        var cnt__thumbnails_outer = document.getElementById('cnt__thumbnails_list-outer');
        // var cnt__thumbnails_list = document.getElementById('cnt__thumbnails_list');

        var cnt__thumbnails_outer_right = cnt__thumbnails_outer.getBoundingClientRect().right;
        var cnt__thumbnails_outer_left = cnt__thumbnails_outer.getBoundingClientRect().left;


        btn__next.addEventListener('click', function(event) {
            event.stopPropagation();
            current_image = +btn__next.getAttribute('data-current-thumb');
            current_type = btn__next.getAttribute('data-type');

            if(thumbnails_list[current_image + 1]
                && thumb_containers_list[current_image + 1].getAttribute('data-image-type') == current_type) {

                current_image++;

                if (thumb_containers_list[current_image].getAttribute('data-image-type') == 'photo') {
                    images_list[0].src = thumbnails_list[current_image].src;
                } else if (thumb_containers_list[current_image].getAttribute('data-image-type') == 'scheme') {
                    images_list[1].src = thumbnails_list[current_image].src;
                }


                var current_image_right_coord = thumb_containers_list[current_image].getBoundingClientRect().right;
                var styles = getComputedStyle(cnt__thumbnails_inner);
                if (current_image_right_coord > cnt__thumbnails_outer_right) {
                    cnt__thumbnails_inner.style.transition = 'left 0.5s ease';
                    var current_position = parseInt(styles.left);
                    cnt__thumbnails_inner.style.left = current_position + (cnt__thumbnails_outer_right - current_image_right_coord) + 'px';
                }


                btn__prev.setAttribute('data-current-thumb', current_image);
                btn__next.setAttribute('data-current-thumb', current_image);

                removeCssClass(thumb_containers_list, 'thumbnails-list__item--active');
                thumb_containers_list[current_image].classList.add('thumbnails-list__item--active');

                var image_data_id = thumb_containers_list[current_image].getAttribute('data-id');

                magnifier.href = '#image_' + image_data_id;
                magnifier.setAttribute('data-id', image_data_id);

                btn_more_image.href = '#image_' + image_data_id;
                btn_more_image.setAttribute('data-id', image_data_id);
            }

        });

        btn__prev.addEventListener('click', function(event) {
            event.stopPropagation();
            current_image = +btn__next.getAttribute('data-current-thumb');
            current_type = btn__next.getAttribute('data-type');

            if(thumbnails_list[current_image - 1]
                && thumb_containers_list[current_image - 1].getAttribute('data-image-type') == current_type) {

                current_image--;

                if (thumb_containers_list[current_image].getAttribute('data-image-type') == 'photo') {
                    images_list[0].src = thumbnails_list[current_image].src;
                } else if (thumb_containers_list[current_image].getAttribute('data-image-type') == 'scheme') {
                    images_list[1].src = thumbnails_list[current_image].src;
                }


                var current_image_left_coord = thumb_containers_list[current_image].getBoundingClientRect().left;

                var styles = getComputedStyle(cnt__thumbnails_inner);
                if (current_image_left_coord < cnt__thumbnails_outer_left) {
                    cnt__thumbnails_inner.style.transition = 'left 0.5s ease';
                    var current_position = parseInt(styles.left);
                    cnt__thumbnails_inner.style.left = current_position + (cnt__thumbnails_outer_left - current_image_left_coord) + 'px';
                }

                btn__prev.setAttribute('data-current-thumb', current_image);
                btn__next.setAttribute('data-current-thumb', current_image);

                removeCssClass(thumb_containers_list, 'thumbnails-list__item--active');
                thumb_containers_list[current_image].classList.add('thumbnails-list__item--active');

                var image_data_id = thumb_containers_list[current_image].getAttribute('data-id');

                magnifier.href = '#image_' + image_data_id;
                magnifier.setAttribute('data-id', image_data_id);

                btn_more_image.href = '#image_' + image_data_id;
                btn_more_image.setAttribute('data-id', image_data_id);
            }

        });
    }

    function removeCssClass($elements, $class_name)
    {
        $elements_length = $elements.length;
        for (var i = 0; i < $elements_length; i++) {
            $elements[i].classList.remove($class_name);
        }
    }
})();
(function () {

    var menu_button = document.getElementById('menu_button');
    if (menu_button) {
        
        var cnt_navigation = document.getElementById('cnt__navigation');
    
        var menu_button_title = document.getElementById('menu_button_title');
        var indicator = false;

        menu_button.addEventListener('click', function() {

            cnt_navigation.classList.toggle('navigation--open');
            if (!indicator) {
                this.classList.toggle('menu-button--onclick');
                setTimeout(function() {
                    menu_button.classList.toggle('menu-button--active');
                }, 400);
                menu_button_title.textContent = 'Закрыть';
                menu_button_title.classList.toggle('menu-button__title--close');
                indicator = !indicator;
            } else {
                this.classList.toggle('menu-button--active');

                setTimeout(function() {
                    menu_button.classList.toggle('menu-button--onclick');
                }, 400);
                menu_button_title.textContent = 'Меню';
                menu_button_title.classList.toggle('menu-button__title--close');
                indicator = !indicator;
            }
        });
    }
})();
(function () {
    window.addEventListener('load', function(){
        var all_images= document.getElementsByTagName('img');
        for (var i = 0; i < all_images.length; i++) {
            if (all_images[i].getAttribute('data-original')) {
                all_images[i].setAttribute('src', all_images[i].getAttribute('data-original'));
            }
        }
    }, false);
})();
(function () {

    var cnt__thumbnails_list = document.getElementById('cnt__thumbnails_list');

    if (cnt__thumbnails_list) {
        var btn__thumb_prev = document.getElementById('btn__thumbnails-nav-prev');
        var btn__thumb_next = document.getElementById('btn__thumbnails-nav-next');

        var cnt__thumbnails_inner = document.getElementById('cnt__thumbnails_list-inner');
        var cnt__thumbnails_outer = document.getElementById('cnt__thumbnails_list-outer');
        var timerId;
        var acceleration = false;

        var styles = getComputedStyle(cnt__thumbnails_inner);
        var list_width = cnt__thumbnails_inner.clientWidth;
        var list_actually_width = cnt__thumbnails_inner.scrollWidth;
        var left_limit = list_width - list_actually_width;

        var image_switcher = document.getElementById('image_switcher');
        image_switcher.addEventListener('click', function() {
            cnt__thumbnails_inner.style.left = '0px';
            cnt__thumbnails_outer.scrollLeft = 0;
            styles = getComputedStyle(cnt__thumbnails_inner);
            list_width = cnt__thumbnails_inner.clientWidth;
            list_actually_width = cnt__thumbnails_inner.scrollWidth;
            left_limit = list_width - list_actually_width;
        });


        btn__thumb_next.addEventListener('mouseenter', function(event) {
            cnt__thumbnails_inner.style.transition = '';

            var current_position = parseInt(styles.left);
            if (current_position > left_limit) {
                timerId = setInterval(function () {
                    if (current_position > left_limit) {
                        if (acceleration) {
                            if ((current_position - 6) > left_limit) {
                                current_position -= 6;
                            } else {
                                current_position = left_limit;
                            }
                        } else {
                            current_position--;
                        }
                        cnt__thumbnails_inner.style.left = current_position + 'px';
                    }
                }, 10);
            }
        });

        btn__thumb_next.addEventListener('mouseleave', function(event) {
            clearInterval(timerId);
        });


        btn__thumb_prev.addEventListener('mouseenter', function(event) {
            cnt__thumbnails_inner.style.transition = '';

            var current_position = parseInt(styles.left);

                timerId = setInterval(function() {
                    if (current_position <= 0) {
                        if (acceleration) {
                            if ((current_position + 6) < 0) {
                                current_position += 6;
                            } else {
                                current_position = 0;
                            }
                        } else {
                            current_position++;
                        }
                        cnt__thumbnails_inner.style.left = current_position + 'px';
                    }
                }, 10);

        });

        btn__thumb_prev.addEventListener('mouseleave', function(event) {
            clearInterval(timerId);
        });

        btn__thumb_next.addEventListener('click', function (event) {
            acceleration = true;
            setTimeout(function(){
                acceleration = false;
            }, 600);
            // var current_position = parseInt(styles.left);
            // if (current_position > left_limit) {
            //     clearInterval(timerId);
            //     if (current_position - list_width > left_limit) {
            //
            //         cnt__thumbnails_inner.style.left = current_position - list_width + 'px';
            //         current_position = parseInt(styles.left);
            //
            //         timerId = setInterval(function () {
            //             if (current_position > left_limit) {
            //                 current_position--;
            //                 cnt__thumbnails_inner.style.left = current_position + 'px';
            //             }
            //         }, 10);
            //     } else {
            //
            //         cnt__thumbnails_inner.style.left = left_limit + 'px';
            //     }
            // }
        });

        btn__thumb_prev.addEventListener('click', function (event) {
            acceleration = true;
            setTimeout(function(){
                acceleration = false;
            }, 600);
            // var current_position = parseInt(styles.left);
            // if (current_position < 0) {
            //     clearInterval(timerId);
            //     if (current_position + list_width < 0) {
            //         cnt__thumbnails_inner.style.left = current_position + list_width + 'px';
            //         current_position = parseInt(styles.left);
            //
            //         timerId = setInterval(function() {
            //             if (current_position <= 0) {
            //                 current_position++;
            //                 cnt__thumbnails_inner.style.left = current_position + 'px';
            //             }
            //         }, 10);
            //     } else {
            //         cnt__thumbnails_inner.style.left = '0px';
            //     }
            // }
        });

    }


})();